var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stack"},[_c('div',{staticClass:"stack-header"},[_c('tabs',{staticClass:"medium-title hack",attrs:{"list":_vm.nav,"button":"network"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('router-link',{attrs:{"active-class":"active","to":item.to}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"button",fn:function(){return [(!_vm.updateRoute)?_c('base-button',{staticClass:"subnet-info__create-btn",attrs:{"icon":_vm.isIcon,"color":_vm.isColor,"tooltip":{
            content: _vm.text,
            autoHide: false,
            placement: 'left-start',
            container: 'body',
            trigger: 'click hover',
          }},on:{"click":function($event){return _vm.clickActionButton()}}},[_vm._v(_vm._s(_vm.$t(`text.${_vm.button?.route}`))+" ")]):_vm._e()]},proxy:true}])})],1),_c('transition',{attrs:{"name":"bubble"}},[_c('router-view',{on:{"change-type-create-button":function($event){return _vm.changeView($event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }